<template>
  <div class="row my-3 justify-content-center">
    <div class="col-auto bold green-text-dark">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="true" id="acceptTerms" v-model="acceptTermsValue">
        <label class="form-check-label" for="flexCheckDefault">
          I accept the terms and conditions.
        </label>
      </div>
    </div>
    <div class="col-auto bold green-text-light cursor p-0">
      <span @click="toggleTerms">Read Here</span>
    </div>
    <ViewTerms v-if="showTerms" />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Accept Terms',
  props: ['acceptTerms'],
  emits: ['update:acceptTerms'],
  components: {
    ViewTerms: defineAsyncComponent(() => import('../components/ViewTerms.vue'))
  },
  data () {
    return {
      showTerms: false
    }
  },
  computed: {
    acceptTermsValue: {
      get () {
        return this.acceptTerms
      },
      set (val) {
        this.$emit('update:acceptTerms', val)
      }
    }
  },
  methods: {
    toggleTerms () {
      this.showTerms = !this.showTerms
    }
  }
}
</script>
